var rp = require('request-promise').defaults({json: true})
var config = require('./config');

const api_root = config.IPBITINKA
const history = {}

export default {
    history: history,

    getBars: function (symbolInfo, resolution, from, to, first, limit) {
        var split_symbol = symbolInfo.name.split(/[:/]/)
        const url = '/api/apinka/historyTradingView'
        const range = resolution === 'D' ? 'DAY' : resolution >= 60 ? 'HOUR' : 'MIN'
        if (range === 'MIN') {
            limit = 6000
        }
        const qs = {
            fsym: split_symbol[0],
            tsym: split_symbol[1],
            toTs: to ? to : '',
            range: range,
            limit: limit ? limit : 2000,
        }

        return rp({
            url: `${api_root}${url}`,
            qs,
        }).then(data => {
            if (data.Response && data.Response === 'Error') {
              // console.log('CryptoCompare API error:', data.Message)
                return []
            }
            if (data.Data.length) {
                var bars = data.Data.map(el => {
                    return {
                        time: el.datetime_unix * 1000, //TradingView requires bar time in ms
                        low: el.low,
                        high: el.high,
                        open: el.open,
                        close: el.close,
                        volume: el.volumen
                    }
                })
                if (first) {
                    var lastBar = bars[bars.length - 1]
                    history[symbolInfo.name] = {lastBar: lastBar}
                }
                return bars
            } else {
                return []
            }
        })
    }
}
