// api/stream.js
import historyProvider from './historyProvider.js'
// we use Socket.io client to connect to cryptocompare's socket.io stream

var config = require('./config');
var io = require('socket.io-client')
var socket_url = config.IPWEBSOCKET
var socket = io(socket_url)
// keep track of subscriptions
var _subs = []

export default {
    subscribeBars: function (symbolInfo, resolution, updateCb, uid, resetCache) {
        const channelString = createChannelString(symbolInfo)
        socket.emit('tradingView', {subs: [channelString]})

        var newSub = {
            channelString,
            uid,
            resolution,
            symbolInfo,
            lastBar: historyProvider.history[symbolInfo.name].lastBar,
            listener: updateCb,
        }
        _subs.push(newSub)
    },
    unsubscribeBars: function (uid) {
        var subIndex = _subs.findIndex(e => e.uid === uid)
        if (subIndex === -1) {
            //console.log("No subscription found for ",uid)
            return
        }
        var sub = _subs[subIndex]
        socket.emit('SubRemove', {subs: [sub.channelString]})
        _subs.splice(subIndex, 1)
    }
}

socket.on('connect', () => {
})
socket.on('disconnect', (e) => {
})
socket.on('error', err => {
})
socket.on('tradingView', (e) => {
    // here we get all events the CryptoCompare connection has subscribed to
    // we need to send this new data to our subscribed charts
    const _data = e.message
    const data = {
        sub_type: 0,
        exchange: 'Bitinka',
        to_sym: _data.firstCurrency,
        from_sym: _data.secondCurrency,
        ts: parseInt(_data.date, 10),
        volume: parseFloat(_data.amoun),
        price: parseFloat(_data.price)
    }

    const channelString = `${data.sub_type}~${data.exchange}~${data.to_sym}~${data.from_sym}`

    const sub = _subs.find(e => e.channelString === channelString)

    if (sub) {
        // disregard the initial catchup snapshot of trades for already closed candles
        if (data.ts < sub.lastBar.time / 1000) {
            return
        }

        var _lastBar = updateBar(data, sub)

// send the most recent bar back to TV's realtimeUpdate callback
        sub.listener(_lastBar)
        // update our own record of lastBar
        sub.lastBar = _lastBar
    }
})

// Take a single trade, and subscription record, return updated bar
function updateBar(data, sub) {
    var lastBar = sub.lastBar
    let resolution = sub.resolution
    if (resolution.includes('D')) {
        // 1 day in minutes === 1440
        resolution = 1440
    } else if (resolution.includes('W')) {
        // 1 week in minutes === 10080
        resolution = 10080
    }
    var coeff = resolution * 60
    var rounded = Math.floor(data.ts / coeff) * coeff
    var lastBarSec = lastBar.time / 1000
    var _lastBar

    if (rounded > lastBarSec) {
        // create a new candle, use last close as open **PERSONAL CHOICE**
        _lastBar = {
            time: rounded * 1000,
            open: lastBar.close,
            high: lastBar.close,
            low: lastBar.close,
            close: data.price,
            volume: data.volume
        }

    } else {
        // update lastBar candle!
        if (data.price < lastBar.low) {
            lastBar.low = data.price
        } else if (data.price > lastBar.high) {
            lastBar.high = data.price
        }

        lastBar.volume += data.volume
        lastBar.close = data.price
        _lastBar = lastBar
    }
    return _lastBar
}

// takes symbolInfo object as input and creates the subscription string to send to CryptoCompare
function createChannelString(symbolInfo) {
    var channel = symbolInfo.name.split(/[:/]/)
    const to = channel[1]
    const from = channel[0]
    // subscribe to the CryptoCompare trade channel for the pair and exchange
    return `0~Bitinka~${from}~${to}`
}
