import * as React from "react";
import "./index.css";
import Datafeed from "./api/";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function getURL() {
  const regex = new RegExp("[\\?&]pair=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? "BTC/USD"
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function getMode() {
  const regex = new RegExp("[\\?&]mode=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? "dark"
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function getModeArray() {
  if (getMode() == "light") {
    return {
      "mainSeriesProperties.showCountdown": true,
      "paneProperties.background": "#FFFFFF",
      "paneProperties.vertGridProperties.color": "#E6E8EA",
      "paneProperties.horzGridProperties.color": "#E6E8EA",
      "symbolWatermarkProperties.transparency": 90,
      "scalesProperties.textColor": "#AAA",
      "mainSeriesProperties.candleStyle.wickUpColor": "#336854",
      "mainSeriesProperties.candleStyle.wickDownColor": "#7f323f",
    };
  } else {
    return {
      "mainSeriesProperties.showCountdown": true,
      "paneProperties.background": "#333333",
      "paneProperties.vertGridProperties.color": "#363c4e",
      "paneProperties.horzGridProperties.color": "#363c4e",
      "symbolWatermarkProperties.transparency": 90,
      "scalesProperties.textColor": "#AAA",
      "mainSeriesProperties.candleStyle.wickUpColor": "#336854",
      "mainSeriesProperties.candleStyle.wickDownColor": "#7f323f",
    };
  }
}

export class TVChartContainer extends React.PureComponent {
  static defaultProps = {
    symbol: "BTC/USD",
    interval: "30",
    containerId: "tv_chart_container",
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: true,
    autosize: true,
    studiesOverrides: {},
  };

  componentDidMount() {
    const widgetOptions = {
      debug: false,
      symbol: getURL(),
      datafeed: Datafeed,
      interval: this.props.interval,
      container_id: this.props.containerId,
      library_path: this.props.libraryPath,
      locale: getLanguageFromURL() || "en",
      disabled_features: ["study_templates"],
      enabled_features: ["use_localstorage_for_settings"],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      overrides: getModeArray(),
    };

    window.TradingView.onready(() => {
      const widget = (window.tvWidget = new window.TradingView.widget(
        widgetOptions
      ));

      widget.onChartReady(function () {
        if (getMode() == "light") {
          widget.addCustomCSSFile("./css/custom-chart-light.css");
        }
      });
    });
  }

  render() {
    return <div id={this.props.containerId} className={"TVChartContainer"} />;
  }
}
